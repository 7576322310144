<template>
  <div class="GlobalFormDemo">
    <GlobalForm
      ref="orderForm"
      :init-data="initData"
      :form-item-list="formItemList"
      :inline="true"
      :isResetdata="isResetForm"
      :labelWidth="80"
      round
      @handleConfirm="handleConfirm"
      confirmBtnName="查询"
    >
      <el-form-item
        :label-width="'80px'"
        label="回收分类"
        slot="typeId"
        style="margin: 0"
      >
        <el-select clearable @change="recoverySeparator" v-model="typeId">
          <el-option
            v-for="item in classList"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item
        :label-width="'80px'"
        label="回收品牌"
        slot="brandNo"
        style="margin: 0"
      >
        <el-select clearable filterable v-model="brandNo" >
          <el-option
            v-for="item in brandNoListData"
            :key="item.id"
            :label="item.name"
            :value="item.brandNo"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item
        :label-width="'100px'"
        label="回收商品"
        slot="phoneName"
        style="margin: 0"
      >
        <el-select
          placeholder="请先选择分类"
          filterable
          :disabled="!typeId"
          clearable
          remote 
          :remote-method="remoteMethod"
          v-model="modelId"
        >
          <el-option
            v-for="item in modelListData"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="预估机况" slot="Computer" style="margin: 0">
        <el-select
          v-model="healthCodeCus"
          placeholder="请选择预估机况"
          clearable
        >
          <el-option
            v-for="item in cupatList"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="订单状态" slot="state" style="margin: 0">
        <el-select
          v-model="state"
          placeholder="请选择订单状态"
          clearable
        >
          <el-option
            v-for="item in orderStatusList"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="报价人" slot="merchantStaffId" style="margin: 0">
        <el-select
          v-model="merchantStaffId"
          placeholder="请选择"
          filterable
          clearable
        >
          <el-option
            v-for="item in shanpsSelectList"
            :key="item.staffId"
            :label="item.staffName"
            :value="item.staffId"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="下单时间" slot="entryTime" style="margin: 0">
        <el-date-picker
          v-model="entryTime"
          type="daterange"
          start-placeholder="开始日期"
          range-separator="~"
          end-placeholder="结束日期"
          clearable
          :default-time="['00:00:00', '23:59:59']"
        >
        </el-date-picker>
      </el-form-item>
      <el-form-item label="报价时间" slot="QuotationTime" style="margin: 0">
        <el-date-picker
          v-model="QuotationTime"
          type="daterange"
          start-placeholder="开始日期"
          range-separator="~"
          end-placeholder="结束日期"
          clearable
          :default-time="['00:00:00', '23:59:59']"
        >
        </el-date-picker>
      </el-form-item>
    </GlobalForm>
  </div>
</template>
  
<script>
import moment from "moment";
import _api from "@/utils/request";
export default {
  name: "Form",
  props: {
    default: () => {
      return [];
    },
    tabIndex: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      merchantId: "",
      talldifferencevalue: "",
      lowDifferencevalue: "",
      rangeIndex: "",
      cupatList: [
        { value: "Nice", label: "靓机" },
        { value: "Little_Snow", label: "小花" },
        { value: "Big_Snow", label: "大花" },
        { value: "Inner_Burst", label: "内爆" },
        { value: "Out_Burst", label: "外爆" },
        { value: "Repair", label: "疑似维修" },
        { value: "NoImage", label: "照片缺失" },
        { value: "PhoneNoMatching", label: "机型与所选不一致" },
      ],
      tallPrice: "",
      shortPrice: "",
      ulPricedata: [],
      uprePrice: "",
      lowPrice: "",
      isResetForm: false,
      merchantStaffId: "", //报价师
      state: "", //订单状态
      companyId: [],
      companyList: [],
      entryTime: null,
      QuotationTime:null,
      shanpsSelectList: [],
      initData: {},
      startTime: "",
      endTime: "",
      baostartTime: "",
      baoendTime: "",
      formItemList: [
        {
          key: "orderNo",
          type: "input",
          labelName: "订单编号",
          placeholder: "请输入订单编号",
        },
        { slotName: "typeId" },
        { slotName: "brandNo" },
        { slotName: "phoneName" },
        { slotName: "state" },
        { slotName: "Computer" }, //预估机况
        { slotName: "merchantStaffId" }, //报价师
        { slotName: "entryTime" }, //下单时间
        { slotName: "QuotationTime" }, //下单时间
      ],
      SeachParams: {},
      classList: [],
      typeId: "",
      healthCodeCus:"",
      modelId: "",
      brandNo: "",
      modelListData: [],
      brandNoListData: [],
      orderStatusList: [
        { value: "W01", label: "待报价" },
        { value: "00", label: "待成交" },
        { value: "G03", label: "待收货" },
        { value: "G02", label: "已收货" },
        { value: "05", label: "降价收货" },
        { value: "10", label: "已取消" },
        { value: "20", label: "已作废" },
        { value: "30", label: "已退回" },],
    };
  },
  created() {
    this.merchantId = this.$route.query.id;
    let startTime = moment()
      .day(moment().day() - 30)
      .format("YYYY-MM-DD 00:00:00"); // 当前时间往前推7天的时间
    let endTime = moment().format("YYYY-MM-DD 23:59:59");
    this.entryTime = [startTime, endTime];
    this.getClassList();
    this.getStatusList();
  },
  mounted(){
    this.handleConfirm({orderNo:""})
  },
  methods: {
    // 获取下拉数据
    getStatusList() {
      // 报价师
      _api
        .recycleStaffList({ positionType: "", merchantId: this.merchantId })
        .then((res) => {
          if (res.code === 1) {
            this.shanpsSelectList = res.data;
          }
        });
      //品牌
      _api.oldBrandSelectList().then((res) => {
        if (res.code === 1) {
          this.brandNoListData = res.data;
        }
      });
    },
    //通过分类请求机型
    getlistSelected(id,name) {
      _api
        .getlistSelected({
          machineTypeId: id,
          name,
        })
        .then((res) => {
          if (res.code === 1) {
            this.modelListData = res.data;
          }
        });
    },
    remoteMethod(name){
      this.getlistSelected(this.typeId,name);
    },
    //回收分类选择
    recoverySeparator(e) {
      console.log(e);
      this.modelId = "";
      if (e) {
        this.getlistSelected(e);
      }
    },
    // 获取分类集合
    getClassList() {
      _api.selectList().then((res) => {
        this.classList = res.data || [];
      });
    },
    //查询****
    handleConfirm(data) {
      console.log("提交了form", data);
      console.log(this.entryTime);
      if (this.entryTime === null) {
        this.startTime = ""
        this.endTime = ""
      } else {
        this.startTime = moment(this.entryTime[0]).format("x")
        this.endTime = moment(this.entryTime[1]).format("x")
      }
      if (this.QuotationTime === null) {
        this.baostartTime = ""
        this.baoendTime = ""
      } else {
        this.baostartTime = moment(this.QuotationTime[0]).format("x")
        this.baoendTime = moment(this.QuotationTime[1]).format("x")
      }
      const baseRequest = {
        orderNo: data.orderNo,
        typeId: this.typeId,
        modelId: this.modelId,
        brandNo: this.brandNo,
        state: this.state,
        healthCodeCus: this.healthCodeCus,
        merchantStaffId: this.merchantStaffId,
        orderEndTime: this.endTime,
        orderStartTime: this.startTime,
        quoteEndTime: this.baoendTime,
        quoteStartTime: this.baostartTime,
        pageNum: this.pageNum,
        pageSize: this.pageSize,
      };
      this.SeachParams = baseRequest;
      this.$store.commit("tagsView/SEACH_PARAMS", baseRequest);
    },
  },
};
</script>
  
<style scoped lang="scss" ref="stylesheet/scss">
.top_aujian {
  overflow: auto;
  width: 225px;
  height: 200px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.ta_center_con {
  cursor: pointer;
  text-align: center;
  line-height: 30px;
  font-size: 14px;
  font-family: FZLanTingHei-M-GBK;
  font-weight: 400;
  margin-bottom: 14px;
  color: #333333;
  width: 100px;
  height: 30px;
  background: #f3f3f3;
  border-radius: 4px;
}

.ta_center_action {
  cursor: pointer;
  text-align: center;
  line-height: 30px;
  font-size: 14px;
  font-family: FZLanTingHei-M-GBK;
  font-weight: 400;
  margin-bottom: 14px;
  color: #0981ff;
  width: 100px;
  height: 30px;
  background: #e4ecfd;
  border-radius: 4px;
}

/deep/.query .el-tag.el-tag--info {
  background-color: #fbfbfb;
  border-color: #cecdcd;
  color: #666666;
}

.baoPrice {
  border: 1px solid #e4ecfd;
  width: 421px;
  height: 32px;
  display: inline-flex;
  align-items: center;
  border-radius: 20px;
  padding: 3px 10px;

  > img {
    width: 15px;
    height: 15px;
  }
}

.bao_section {
  flex: 1;
  font-size: 12px;
  font-family: FZLanTingHei-M-GBK;
  font-weight: 400;
  color: #a1a4af;
  // width: 300px;
  align-items: center;
  justify-content: space-evenly;
  display: flex;
}

.pulldown_content {
  width: 265px;
  padding: 0 20px;
  height: 300px;
  position: relative;
}

.fc_input {
  font-size: 12px;
  font-family: FZLanTingHei-M-GBK;
  font-weight: 400;
  color: #333333;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 15px auto;
  width: 225px;
  height: 30px;
  // background: #F9FBFD;
  border: 1px solid #c1d1ff;
  border-radius: 4px;
}

.put_price {
  width: 80px;

  /deep/.el-input__inner {
    border: none !important;
  }
}

.foot_content {
  position: absolute;
  bottom: 0;
  width: 225px;
  height: 95px;
  // box-shadow: 0px 2px 14px 1px rgba(118, 133, 164, 0.31);
}

/deep/.el-dropdown-menu__item {
  line-height: 30px !important;
  padding: 0 !important;
}

.pc_footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.GlobalFormDemo {
  /deep/.el-checkbox-group {
    margin-left: 15px;
  }

  // /deep/.el-form-item--small.el-form-item{
  //   margin-bottom: 0;
  // }
  .flex {
    display: flex;
    align-items: center;
  }

  .time-type {
    /deep/.el-input__inner {
      border: none;
    }
  }
}

/deep/ input::-webkit-outer-spin-button,
/deep/ input::-webkit-inner-spin-button {
  -webkit-appearance: none !important;
}

/deep/ input[type="number"] {
  -moz-appearance: textfield;
}
</style>
  