export const formItemList = [
    {
        labelName: "估价单号",
        key: 'channelAssessNo',
        type: "input",
        lableWidth: "80px",
        placeholder: "请输入订单编号",
    },
    {
        labelName: "回收单号",
        key: 'recycleOrderNo',
        type: "input",
        placeholder: "请输入订单编号",
    },
    {
        labelName: "门店商户",
        key: 'companyId',
        //需要被清除的key，相当于子
        childrenKey: "storeId",
        type: "selectFilterable",
        placeholder: "请选择",
        lableWidth: '80px',
        clear: true,
        option: [],
        clearFun: () => false
    },
    {
        labelName: "估价门店",
        key: 'storeId',
        fatherKey: "companyId",
        type: "selectFilterable",
        placeholder: "请先选择门店商家",
        lableWidth: '80px',
        clear: true,
        option: [],
        disabled: true,
        clearFun: () => false
    },
    {
        labelName: "估价店员",
        key: 'staffName',
        type: "input",
        placeholder: "请输入下单店员姓名",
    },
    {
        labelName: "回收分类",
        key: 'typeId',
        type: "selectFilterable",
        placeholder: "请选择分类",
        lableWidth: '80px',
        clear: true,
        option: [],
        clearFun: () => false
    },
    // 旧机情况
    {
        key: 'healthCode',
        type: 'select',
        lableWidth: '80px',
        labelName: '机况分类',
        option: [],
    },
    {
        labelName: "回收商品",
        key: 'machineName',
        type: "input",
        placeholder: "请输入商品名称",
    },
    {
        labelName: "是否关联回收下单",
        key: 'isBuildRecycle',
        type: "selectFilterable",
        placeholder: "请选择关联回收下单方式",
        lableWidth: '160px',
        clear: true,
        option: [
            {
                label: '全部',
                value: null
            },
            {
                label: '是',
                value: '02'
            },
            {
                label: '否',
                value: "01"
            }
        ],
        clearFun: () => false
    },
    {
        labelName: "估价渠道",
        key: 'channel',
        type: "select",
        placeholder: "请选择",
        clear: true,
        option: [
            {
                label: '采货侠',
                value: "chx"
            },
            {
                label: '分毫',
                value: 'fh'
            }
        ],
        clearFun: () => false
    },
    {
        lableWidth: "80px",
        labelName: "下单时间",
        key: 'time',
        type: "daterange",
    },
]

export const tableDatas = {
    // 加载
    loading: false,
    // 当前数据
    currentData: {},
    // 表数据
    data: [],
    // 单数
    allCount: 0,
    // 表列
    tableColumns: [
        {
            prop: "index",
            label: "序号",
            width: "60px"
        },
        {
            prop: "channelAssessNo",
            label: "估价单号",
        },
        {
            prop: "createTime",
            label: "估价时间",
        },
        {
            prop: "companyName",
            label: "门店商户",
        },
        {
            prop: "staffName",
            label: "估价店员",
        },
        {
            prop: "typeName",
            label: "旧机分类",
        },
        {
            prop: "brandName",
            label: "旧机品牌",
        },
        {
            prop: "machineName",
            label: "估价机型",
        },
        {
            prop: "report",
            label: "验机报告",
        },
        {
            prop: "healthCode",
            label: "机况分类"
        },
        {
            prop: "showPrice",
            label: "显示预估价",
        },
        {
            prop: "channelPrice",
            label: "原始预估价",
        },
        {
            prop: "recycleOrderNo",
            label: "关联回收单号",
        },
        {
            prop: "recycleOrderState",
            label: "订单状态",
        },
        {
            prop: "maxQuotePrice",
            label: "回收单最高报价",
        },
        {
            prop: "channel",
            label: "估价渠道"
        }
    ]
}

// 估价单验机报告弹窗
export const dialogProps = {
    title: "初步验机报告",
    show: false,
    width: '40%',
    report: {},
    // 旧机信息
    infoData: {},
    // 检测项
    tableData: [
        {
            one: "1",
            two: "2",
            three: "3"
        },
        {
            one: "1",
            two: "2",
            three: "3"
        }
    ]
}
// 预估价明细
export const detailDialogPorps = {
    show: false,
    title: "预估价明细",
    width: '30%',
}

// --------------以上都是估价单的状态--------------

// --------------订单列表-------------------
// 回收订单串号验机报告弹窗
export const imeiTestProps = {
    title: "旧机功能情况",
    show: false,
    width: '49%',
    isLoadding: false,
    phoneName: "",//另一个机型name
    // 02代表渲染功能情况
    type: "",
    // 检测项
    tableData: [],
    // 功能情况
    functionList: [],
    // 系统检测结果
    systemTestResult: [],
    // 是否无法检测
    unableDetectionCause: "",
    // 是否系统检测且存在检测结果
    isSystem: false,
    // 其他功能标题显示，原需求模糊，抄详情写
    isOtherTitileShow: false,
    // 其他功能表格 原需求模糊，抄详情写
    isOtherTableShow: false,
    // 是否显示成色
    isFinenessShow: false,
    // 成色数据
    finenessData: [],
    // 旧机照片
    photoArr: [],
    // 系统验机项
    systemTestList: [],
    orderMode: "",//下单模式
    funcDetail: {},
    sku: { skus: [] },//外部规格
    // 保存值，防止被删除
    allSystemTestList: [
        {
            value: "",
            key: "mobileNetworks",
            lable: "1.移动数据",
            isSystem: true
        },
        {
            value: "",
            key: "wlan",
            lable: "2.WI-FI",
            isSystem: true
        },
        {
            value: "",
            key: "bluetooth",
            lable: "3.蓝牙",
            isSystem: true
        },
        {
            value: "",
            key: "camera",
            lable: "4.摄像头",
            tipKey: "cameraCause",
            tips: ""
        },
        {
            value: "",
            key: "fingerprintOrFace",
            lable: "5.指纹/面容识别",
        },
        {
            value: "",
            key: "screenTouch",
            lable: "6.触屏功能",
        },
        {
            value: "",
            key: "screenDisplay",
            lable: "7.屏幕显示",
            tipKey: "screenDisplayCause",
            tips: ""
        },
        {
            value: "",
            key: "conversation",
            lable: "8.拨打电话"
        },
        {
            value: "",
            key: "charge",
            lable: "9.充电功能"
        }
    ],
}
